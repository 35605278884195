import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import Layout from '../../components/Layout'

import programmingIcon from '../../img/pages/technologies/Programming-Languages.svg';
import technologiesIcon from '../../img/pages/technologies/Technologies-Frameworks.svg';
import databasesIcon from '../../img/pages/technologies/Databases1.svg';
import cloudComputingIcon from '../../img/pages/technologies/Cloud-computing.svg';
import webServersIcon from '../../img/pages/technologies/Web-Servers.svg';
import testingIcon from '../../img/pages/technologies/software-testing-technologies.svg';

const TechnologyRow = styled(Row)`
  border-bottom: 1px solid #ccc;
  padding: 35px 0;
  &:last-child {
    border-bottom: none;
  }
`
const TechnologyHeader = styled.h3`
  padding-bottom: 15px;
  @media(max-width: 767px) {
    text-align: center;
  }
`
const TechnologyImageCol = styled(Col)`
  @media(max-width: 767px) {
    text-align: center;
    margin-bottom: 15px;
  }
`
const TechnologyItem = styled.p`
  position: relative; padding-left: 200px; margin: .3rem 0 0 0;
  @media screen and (max-width: 960px) {
    padding-left: 120px;
  }
  @media screen and (max-width: 560px) {
    padding-left: 20px;
  }
`
const TechnologyItemTitle = styled.span`
  position: absolute; left:0;top:0;display:block;width:165px;color:#1f1f1f;text-align:right;
  @media screen and (max-width: 960px) {
    width:100px;
  }
  @media screen and (max-width:560px) {
    position:relative; width:auto; margin-left: -20px;text-align: left;
  }
`

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="technologies">
        <Helmet
          title="Technology Expertise | Bishop Software"
          meta={[
            { name: 'description', content: 'We have expertise in a wide variety of languages and frameworks like Javascript, Java, PHP, C#, C++, C, Go, Python, Erlang, Elixir. We can help with your next software / web project. Let\'s talk.' },
            { name: 'keywords', content: 'software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
          ]}
        />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #09203A 0%, #207cca 73%, #6977B2 90%, #BC6591 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#1076B8 0px, #072944 100%)' section="technologies" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: 'rgb(243, 174, 189)', fontSize: '40px', fontWeight: 300}}>Technology Expertise</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}></h3>
              <div style={{marginTop: '35px', paddingBottom: '35px'}}>
                <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
              </div>
            </div>
          </Container>
        </section>
      <Scroll.Element name="contact" />

        <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <Container>
        <TechnologyRow>
          <TechnologyImageCol md="3" xs="12">
            <img src={programmingIcon} />
          </TechnologyImageCol>
          <Col md="9" xs="12">
            <TechnologyHeader>Programming Languages</TechnologyHeader>
            <p>Javascript, Java, PHP, C#, C++, C, Go, Python, Erlang, Elixir</p>
          </Col>
        </TechnologyRow>

        <TechnologyRow>
          <TechnologyImageCol md="3" xs="12">
            <img src={technologiesIcon} />
          </TechnologyImageCol>
          <Col md="9" xs="12">
            <TechnologyHeader>Technologies &amp; Frameworks</TechnologyHeader>
            <TechnologyItem>
              <TechnologyItemTitle>Backend:</TechnologyItemTitle>
              PHP, Node.js, .NET, Go, Python
            </TechnologyItem>
            <TechnologyItem>
              <TechnologyItemTitle>Frontend:</TechnologyItemTitle>
              React.js, Angular, Vue
            </TechnologyItem>
            <TechnologyItem>
              <TechnologyItemTitle>Mobile:</TechnologyItemTitle>
              Android, Swift, Xamarin, Ionic, React Native, Apache Cordova
            </TechnologyItem>
            <TechnologyItem style={{marginTop: '1rem'}}>
              <TechnologyItemTitle>Frameworks:</TechnologyItemTitle>
              ASP.NET / .NET Core, ExpressJS, GraphQL Apollo, Redux, Laravel, Yii Framework, Zend Framework, Django, Bootstrap, Phoenix
            </TechnologyItem>
            <TechnologyItem style={{marginTop: '1rem'}}>
              <TechnologyItemTitle>.NET:</TechnologyItemTitle>
              Microsoft .NET Framework, .NET Core, ASP.NET, C#.NET, ASP.NET MVC, LINQ, Entity Framework, Azure
            </TechnologyItem>
            <TechnologyItem style={{marginTop: '1rem'}}>
              <TechnologyItemTitle>APIs:</TechnologyItemTitle>
              Salesforce, Facebook API, Google API, Yahoo API, Twitter, WebGL
            </TechnologyItem>
            <TechnologyItem style={{marginTop: '1rem'}}>
              <TechnologyItemTitle>Map Services:</TechnologyItemTitle>
              Google Maps, Yandex Maps, Yahoo! Maps, Live Search Maps, OpenStreet Map (OSM), Leaflet Map, Here Maps
            </TechnologyItem>
            <TechnologyItem style={{marginTop: '1rem'}}>
              <TechnologyItemTitle>CMS:</TechnologyItemTitle>
              WordPress, Joomla, VirtueMart, WooCommerce
            </TechnologyItem>
            <TechnologyItem style={{marginTop: '1rem'}}>
              <TechnologyItemTitle>Other Technologies and Languages:</TechnologyItemTitle>
              WebRTC, Marionette.js, PhoneGap, Web Services, SOAP, JSON, WHMCS, CSS3, SCSS Preprocessor, HTML5, XML, SVG, Canvas, PHPBB, Sockets, WebSockets, Windows Services, Braintree, NFS-tags, Flash, Handlebars
            </TechnologyItem>
          </Col>
        </TechnologyRow>

        <TechnologyRow>
          <TechnologyImageCol md="3" xs="12">
            <img src={databasesIcon} />
          </TechnologyImageCol>
          <Col md="9" xs="12">
            <TechnologyHeader>Database</TechnologyHeader>
            <p>Oracle, MS SQL, MySQL, PostgreSQL, MongoDB, Microsoft Access, SQLite, Redis</p>
          </Col>
        </TechnologyRow>
        
        <TechnologyRow>
          <TechnologyImageCol md="3" xs="12">
            <img src={cloudComputingIcon} />
          </TechnologyImageCol>
          <Col md="9" xs="12">
            <TechnologyHeader>Cloud Computing</TechnologyHeader>
            <p>Amazon, Azure, Google Cloud Platform, JBoss, WebLogic, WebSphere</p>
          </Col>
        </TechnologyRow>
        
        <TechnologyRow>
          <TechnologyImageCol md="3" xs="12">
            <img src={webServersIcon} />
          </TechnologyImageCol>
          <Col md="9" xs="12">
            <TechnologyHeader>Application / Web Servers</TechnologyHeader>
            <p>Apache, Apache Solr, IIS, Nginx, Cassini, JBoss, WebLogic, WildFly</p>
          </Col>
        </TechnologyRow>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container className="text-center">
        <h2 style={{color: '#fff'}}>How it all works</h2>
        <p>We offer two contractual models: "Time &amp; Materials" and "Fixed Price" to accomodate our customers. We also can work using both Agile and non-Agile methodologies, and can combine them to get the most out of each methodology to your benefit. Find out how we use these technologies, models, and methodologies together to build your dream application.</p>
        <div style={{marginTop: '40px'}}>
          <Link to="/about-us/how-we-work" className="btn btn-primary round">How We Work</Link>
        </div>
      </Container>
    </section>


    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="technologies" />
      </div>
  </Layout>
    )
  }
}
  